import React, { useEffect, useMemo, useState } from "react";
import "./CategoryPage.css";
import Green from "../../../assets/images/CategoryPage/greens.png";
import Bread from "../../../assets/images/CategoryPage/breads and cakes.png";
import Cleaner from "../../../assets/images/CategoryPage/cleaners.png";
import Dairy from "../../../assets/images/CategoryPage/dairy and eggs.png";
import Sweets from "../../../assets/images/CategoryPage/sweets.png";
import MainTemplate from "../../../components/MainTemplate";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { customActionModalStyles, customModalStyles, debounce, } from "../../../utils/helperFunctin";
import { getTokenApi, postTokenApi, deleteTokenApi, } from "../../../api_interface/apis";
import { CATEGORY_PATH, HIBEE_PRODUCT_URL, } from "../../../api_interface/apiURLs";
import { toast } from "react-toastify";

function CategoryPage() {
	const [itemOffset, setItemOffset] = useState(0);
	const [modal, setModal] = useState(false);
	const [actionModal, setActionModal] = useState({ loader: false, show: false, type: "", data: {}, });
	const [categories, setCategories] = useState([]);
	const [category, setcategory] = useState({ name: "", image: "", imageFile: "", });
	const [error, setError] = useState({});
	const [loader, setLoader] = useState(false);
	const [sort, setSort] = useState("")
	const [query, setQuery] = useState("")
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const endOffset = itemOffset + itemsPerPage;
	const pageCount = Math.ceil(categories.length / itemsPerPage);
	const currentItems = categories.slice(itemOffset, endOffset);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % categories.length;
		setItemOffset(newOffset);
	};

	const getCategories = async search => {
		setLoader(true)
		const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + `?sort=${sort}&query=${search || query || ""}`);
		if (res.status == 200) {
			setCategories(res?.data?.data);
			setLoader(false)
		}
	};

	const validation = () => {
		const { name, image, imageFile, _id } = category;
		let error = {};
		if (!name) {
			error.name = "Name Required";
		}
		if (!(image || imageFile)) {
			error.image = "Image Required";
		}
		if (Object.keys(error)?.length) {
			setError(error);
			return false;
		} else {
			setError({});
			return true;
		}
	};

	const Submit = async (e) => {
		try {
			e.preventDefault();
			setLoader(true);
			const { name, image, imageFile, _id } = category;
			if (validation()) {
				const fd = new FormData();
				fd.append("name", name);
				if (imageFile) {
					fd.append("image", imageFile);
				}
				if (_id) {
					fd.append("_id", _id);
				}
				const res = await postTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH, fd, {
					"Content-Type": "multipart/form-data",
				});
				setModal(false);
				getCategories();
				toast.success("Category Saved", {
					position: "top-center",
					autoClose: 2000,
				});
			}
			setLoader(false);
		} catch (err) {
			const message = err?.response?.data?.message || "Something went wrong";
			toast.error(message, { position: "top-center", autoClose: 2000 });
		}
	};

	const deleteCategory = async (id) => {
		setActionModal({ ...actionModal, loader: true });
		const res = await deleteTokenApi(
			HIBEE_PRODUCT_URL + CATEGORY_PATH + `/${id}`
		);
		setActionModal({});
		getCategories();
	};

	const archieveCategory = (cat) => {
		setActionModal({ ...actionModal, loader: true });

		setActionModal({});
		getCategories();
	};

	const onChange = (e, type) => {
		if (type === "IMAGE") {
			setcategory({ ...category, image: "", imageFile: e.target?.files?.[0] });
		} else {
			setcategory({ ...category, name: e.target.value });
		}
		setTimeout(() => { }, 500);
	};

	const closeCategoryModal = () => {
		setModal(false);
		setcategory({});
		setLoader(false);
		setError({});
	};

	const updateSort = e => {
		setSort(e.target.value)
	}

	const getSearchedData = useMemo(() => debounce(async (search) => {
		getCategories(search)
	}, 500), [])

	useEffect(() => {
		getCategories()
	}, [sort])

	useEffect(() => {
		getSearchedData(query)
	}, [query])

	useEffect(() => {
		if (Object.keys(error)?.length) {
			validation();
		}
	}, [error]);

	return (
		<MainTemplate
			categoryName="Categories"
			categoryList={["Admin", "Categories"]}
		>
			<div className="row mb-4 rounded p-2 bg-white">
				<div className="col-sm-6 my-3 col-md-2">
					<select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
						<option value="" selected>Sort by</option>
						<option value="name">Alphabatically</option>
						<option value="dateold">Date: Acsending</option>
						<option value="datenew">Date: Descending</option>
					</select>
				</div>
				<div className="col-sm-6 my-3 col-md-2">
					<select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
						<option value="10" selected>10 per page</option>
						<option value="20">20 per page</option>
						<option value="50">50 per page</option>
						<option value="100">100 per page</option>
					</select>
				</div>
				<div className="col-sm-6 my-3 col-md-3">
					<input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
				</div>
				<div className="d-none d-md-block col-md-3"></div>
				<div className="col-sm-6 my-3 col-md-2">
					<button
						type="button"
						className="t-12 w-100 btn btn-outline-dark"
						onClick={() => {
							setModal(true);
							setcategory({});
						}}
					>

						Add Category
					</button>
				</div>
			</div>

			<div className="row rounded px-3 bg-white">
				{/* header */}
				<div className="row m-0 ">
					<div className="font-weight-bold t-12 text-uppercase my-3 col-md-1">
						Photo
					</div>
					<div className="font-weight-bold t-12 text-uppercase my-3 col-md-5">
						Category Name
					</div>
					<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-4">
						Sub category count
					</div>
					<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
						Edit
					</div>
					<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1"></div>
				</div>

				{/* body */}
				<div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
					{currentItems?.map((el) => (
						<div
							className="row border rounded m-0 px-2  mb-2 d-flex align-items-center"
							key={el?._id}
						>
							<div className="t-12 text-uppercase my-3 col-md-1">
								<div className="categoryImageDiv shadow-sm  rounded p-1">
									<Link
										className="text-dark"
										to={`/categories/${el?._id}`}
										style={{ textDecoration: "none" }}
									>
										<img
											src={el?.image}
											className="categoryImage h-100 rounded"
										/>
									</Link>
								</div>
							</div>
							<div className="t-12 text-uppercase my-3 col-md-5">
								<Link
									className="text-dark"
									to={`/categories/${el?._id}`}
									style={{ textDecoration: "none" }}
								>
									{el?.name}
								</Link>
							</div>
							<div className="t-12 text-uppercase my-3 col-md-4">
								{el?._id} {el?.subCategory}
							</div>
							<div className="t-12 text-uppercase my-3 col-md-1">
								<svg
									className="bi bi-pencil border-bottom border-dark"
									onClick={() => {
										setModal(true);
										setcategory(el);
									}}
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									viewBox="0 0 16 16"
								>
									<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
								</svg>
							</div>
							<div className="t-12 text-uppercase my-3 col-md-1">
								<div class="dropdown">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										data-menu-target={`drop-down-menu-${el?._id}`}
										className="bi bi-three-dots dropdown-btn cursorPointer"
										viewBox="0 0 16 16"
									>
										<path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
									</svg>
									{/* <button class="dropbtn">Dropdown</button> */}
									<div
										id={`drop-down-menu-${el?._id}`}
										className="dropdown-content p-2"
									>
										<div
											className="px-3 py-2 d-flex cursorPointer"
											onClick={() =>
												setActionModal({
													...actionModal,
													show: true,
													data: el,
													type: "DELETE",
												})
											}
										>
											<span className="mx-2 d-flex align-items-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="currentColor"
													class="bi bi-trash3"
													viewBox="0 0 16 16"
												>
													<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
												</svg>
											</span>
											<span className="mx-2">
												<div className="font-weight-bold">Delete</div>
												<div className="">Delete this product</div>
											</span>
										</div>
										{/* <div className="px-3 py-2 d-flex cursorPointer" onClick={() => setActionModal({...actionModal, show:true, data: el, type: "ARCHIEVE"})}>
												<span className="mx-2 d-flex align-items-center">
													<svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M2.44444 22C1.77222 22 1.19656 21.7609 0.717444 21.2826C0.239148 20.8034 0 20.2278 0 19.5556V4.27778C0 3.97222 0.0509259 3.69722 0.152778 3.45278C0.25463 3.20833 0.387037 2.97407 0.55 2.75L2.26111 0.672222C2.42407 0.448148 2.62778 0.279889 2.87222 0.167445C3.11667 0.0558149 3.38148 0 3.66667 0H18.3333C18.6185 0 18.8833 0.0558149 19.1278 0.167445C19.3722 0.279889 19.5759 0.448148 19.7389 0.672222L21.45 2.75C21.613 2.97407 21.7454 3.20833 21.8472 3.45278C21.9491 3.69722 22 3.97222 22 4.27778V19.5556C22 20.2278 21.7609 20.8034 21.2826 21.2826C20.8034 21.7609 20.2278 22 19.5556 22H2.44444ZM2.93333 3.66667H19.0667L18.0278 2.44444H3.97222L2.93333 3.66667ZM2.44444 6.11111V19.5556H19.5556V6.11111H2.44444ZM11 18.3333L15.8889 13.4444L14.1778 11.7333L12.2222 13.6889V8.55556H9.77778V13.6889L7.82222 11.7333L6.11111 13.4444L11 18.3333ZM2.44444 19.5556H19.5556H2.44444Z" fill="#666666"/>
													</svg>
												</span>
												<span className="mx-2">
													<div className="font-weight-bold">Archive</div>
													<div className="">Archive this product</div>
												</span>
											</div> */}
										{/* <a href="#home">Home</a>
											<a href="#about">About</a>
											<a href="#contact">Contact</a> */}
									</div>
								</div>
							</div>
						</div>
					))}

					{loader == true &&
						<div className="text-center mt-5" >
							<button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
								Loading...
							</button>
						</div>
					}

				</div>
			</div>
			<div className="row m-2 d-flex justify-content-center align-items-center">
				<ReactPaginate
					breakLabel="..."
					nextLabel=">"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					pageCount={pageCount}
					previousLabel="<"
					renderOnZeroPageCount={null}
					pageClassName="page-item mb-0"
					subContainerClassName="pages "
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakClassName="page-item"
					breakLinkClassName="page-link"
					marginPagesDisplayed={2}
					containerClassName="pagination"
					activeClassName="active"
				/>
			</div>
			{/* Catgory model */}
			<Modal
				isOpen={modal}
				onRequestClose={closeCategoryModal}
				style={customModalStyles}
				contentLabel="Example Modal"
				ariaHideApp={false}
			>
				<div className="px-3 py-3">
					<div className="row">
						<span className="d-flex justify-content-end cursorPointer">
							<svg
								onClick={closeCategoryModal}
								className="d-inline"
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
							</svg>
						</span>
					</div>
					<div className="row">
						<div className="form-group my-3">
							<label className="my-2">Category Name</label>
							<input
								name="name"
								value={category?.name}
								onChange={(e) => onChange(e)}
								type="text"
								className={`placeholder-light form-control my-2 py-2 px-3 ${error?.name && "border-danger"
									}`}
								placeholder="eg: Apple New Launch Pad"
							/>
							<span className="text-danger">{error?.name}</span>
						</div>
					</div>
					<div className="row">
						<div className="form-group my-3">
							<label className="my-2">Upload Image</label>
							<input
								name="image"
								type="file"
								onChange={(e) => onChange(e, "IMAGE")}
								accept="image/png, image/gif, image/jpeg"
								className={`py-2 opacity0 position-absolute placeholder-light form-control py-2 px-3 border`}
								placeholder="eg: https://www.apple.com/in/watch"
							/>
							<div
								className={`my-2 py-2 select-text-light d-flex align-items-center px-3 border rounded ${error?.image && "border-danger"
									}`}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									class="bi bi-cloud-arrow-up"
									viewBox="0 0 16 16"
								>
									<path
										fill-rule="evenodd"
										d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
									/>
									<path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
								</svg>
								<span className="px-2">Upload your image file</span>
							</div>
							{category?.image ||
								(category?.imageFile &&
									URL.createObjectURL(category?.imageFile)) ? (
								<div>
									<img
										id="categoryPageModalImage"
										src={
											category?.image ||
											URL.createObjectURL(category?.imageFile)
										}
										className="rounded shadow-sm mt-2"
										style={{
											width: "100px",
											height: "100px",
											objectFit: "cover",
										}}
									/>
								</div>
							) : (
								""
							)}
							<span className="text-danger">{error?.image}</span>
						</div>
					</div>
					<div className="form-group my-3">
						<button
							disabled={loader}
							className="btn btn-dark text-warning px-5"
							onClick={Submit}
						>
							{loader ? (
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="sr-only"></span>
								</div>
							) : (
								"Submit"
							)}
						</button>
					</div>
				</div>
			</Modal>

			{/* Action Model */}
			<Modal
				isOpen={actionModal?.show}
				onRequestClose={() => setActionModal({})}
				style={customActionModalStyles}
				contentLabel="Action Modal"
				ariaHideApp={false}
			>
				<div className="px-3 py-3">
					<div className="row">
						<span className="d-flex justify-content-end cursorPointer">
							<svg
								onClick={() => setActionModal({})}
								className="d-inline"
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
							</svg>
						</span>
					</div>
					<div className="row">
						<h4 className="text-danger d-flex justify-content-center">
							Do you want to {actionModal?.type} Category?
						</h4>
					</div>
					<div className="row my-5">
						<h5 className="d-flex justify-content-center">
							{actionModal?.data?.name}
						</h5>
					</div>
					<div className="form-group my-3 d-flex justify-content-center">
						<button
							disabled={actionModal?.loader}
							className="btn btn-dark me-3 mb-2 text-warning px-5"
							onClick={() =>
								actionModal?.type === "DELETE"
									? deleteCategory(actionModal?.data?._id)
									: archieveCategory(actionModal?.data?._id)
							}
						>
							{actionModal?.loader ? (
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="sr-only"></span>
								</div>
							) : (
								"Submit"
							)}
						</button>
						<button
							disabled={actionModal?.loader}
							className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
							onClick={() => setActionModal({})}
						>
							Cancel
						</button>
					</div>
				</div>
			</Modal>
		</MainTemplate>
	);
}

export default CategoryPage;
