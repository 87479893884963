import React from "react";
import "./HomepageModifier.css";
import MainTemplate from "../../../components/MainTemplate";

function HomepageModifier() {

	return (
		<MainTemplate categoryName="Products" categoryList={["Homepage Modifier", "Products"]}>
			<div className="row mb-4 rounded bg-white homepageModifierPadding">
				<div className="form-group py-2">
					<label>Title</label>
					<input type="text" className="w-75 placeholder-light form-control py-2 px-3" placeholder="eg: New Launch" />
				</div>
				<div className="form-group py-2">
					<label>Description</label>
					<input type="text" className="w-75 placeholder-light form-control py-2 px-3" placeholder="eg: An apple a day keeps the doctor away" />
				</div>
				<div className="form-group py-2">
					<label>Link</label>
					<input type="text" className="w-75 placeholder-light form-control py-2 px-3" placeholder="eg: https://www.apple.com/in/watch" />
				</div>
				<div className="form-group py-2">
					<label>Category</label>
					<select className="w-75 py-2 select-text-light form-select" aria-label="Default select example" outline="none">
						<option selected>Munich & Snacks</option>
						<option value="1">One</option>
						<option value="2">Two</option>
						<option value="3">Three</option>
					</select>
					
				</div>
				<div className="form-group py-2  mb-4 position-relative">
					<label>Select Background Image</label>
					<input type="file" className="homepageModifierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3" placeholder="eg: https://www.apple.com/in/watch" />
					<div className="homepageModifierInput select-text-light d-flex align-items-center justify-content-center border  rounded w-75">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"/>
							<path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
						</svg>
						<span className="px-2">Upload your image file</span>
					</div>				
				</div>
				<div className="form-group py-2">
					<button className="btn btn-dark text-warning px-5">Save</button>
				</div>
				
			</div>
		</MainTemplate>
	);
}

export default HomepageModifier;