import React, { useState } from "react";
import "./HomepageModifier.css";
import MainTemplate from "../../../components/MainTemplate";
import Drag from "../../../assets/images/drag.svg"
import ReactPaginate from 'react-paginate';

function HomepageModifierCategory() {

	const data = [
		{ id: 1, category: "Fruits & Vegetables"},
		{ id: 2, category: "Dairy & Eggs"},
		{ id: 3, category: "Sweet Tooth"},
		{ id: 4, category: "Kitchen & Cleaning"},
		{ id: 5, category: "Bread & Baker"},
		{ id: 6, category: "Fruits & Vegetables"},
		{ id: 7, category: "Dairy & Eggs"},
		{ id: 8, category: "Sweet Tooth"},
		{ id: 9, category: "Kitchen & Cleaning"},
		{ id: 10, category: "Bread & Baker"},
		{ id: 11, category: "Bread & Baker"},
		{ id: 12, category: "Fruits & Vegetables"},
		{ id: 13, category: "Dairy & Eggs"},
		{ id: 14, category: "Sweet Tooth"},
		{ id: 15, category: "Kitchen & Cleaning"},
		{ id: 16, category: "Bread & Baker"},
		{ id: 17, category: "Fruits & Vegetables"},
		{ id: 18, category: "Dairy & Eggs"},
		{ id: 19, category: "Sweet Tooth"},
		{ id: 20, category: "Kitchen & Cleaning"},
		
	]

	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 10
	const endOffset = itemOffset + itemsPerPage;
	const pageCount = Math.ceil(data.length / itemsPerPage);
	const currentItems = data.slice(itemOffset, endOffset);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % data.length;
		setItemOffset(newOffset);
	};

	return (
		<MainTemplate categoryName="Category" categoryList={["HomepageModifier", "Category"]}>
			<div className="row mb-4 d-flex align-items-end px-0">
				<div className="col-md-6 font-weight-bold">Sub Categories</div>
				<div className="col-md-6 d-flex justify-content-end pe-0"><input type="text" className="w-50 form-control" placeholder="Search" /></div>
			</div>

			<div className="row mb-4 rounded p-2 px-4 bg-white overflow-auto">
				<div className="row p-2 font-weight-bold text-uppercase">
					<div className="col-1 p-2"></div>
					<div className="col-1 p-2">No.</div>
					<div className="col-4 p-2">Title</div>
					<div className="col-4 p-2">Visibility</div>
					<div className="col-1 p-2"></div>
					<div className="col-1 p-2"></div>
				</div>
				<div className="row homepageModifierCategoryInventoryScroll overflow-auto">
					{
						currentItems?.map((el, index) => (
							<div className="row border rounded p-3 mb-2 ">
								<div className="col-1 p-2 d-flex flex-column">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up" viewBox="0 0 16 16">
										<path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"/>
									</svg>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
										<path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
									</svg>

								</div>
								<div className="col-1 p-2">{el?.id}</div>
								<div className="col-4 p-2">{el?.category}</div>
								<div className="col-4 p-2">
									<label class="switch">
										<input class="switch-input" type="checkbox" />
										<span class="switch-label"></span> 
										<span class="switch-handle"></span> 
									</label>
								</div>
								<div className="col-1 p-2"><img src={Drag} /></div>
								<div className="col-1 p-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
										<path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
									</svg>
								</div>
							</div>
						))
					}
				</div>
			</div>

			<div className="row m-2 d-flex justify-content-center align-items-center">
				<ReactPaginate
					breakLabel="..."
					nextLabel=">"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					pageCount={pageCount}
					previousLabel="<"
					renderOnZeroPageCount={null}
					pageClassName="page-item mb-0"
					subContainerClassName="pages "
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakClassName="page-item"
					breakLinkClassName="page-link"
					marginPagesDisplayed={2}
					containerClassName="pagination"
					activeClassName="active"
				/>
			</div>
		</MainTemplate>
	);
}

export default HomepageModifierCategory;