import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import CategoryList from "../CategoryList/CategoryList";
// import "./LandingPage.css";
import Logo from "../assets/images/logo.png"
import User from "../assets/images/user.png"
import { addCategory } from "../redux/reducers/sidebarSlice";
import Breadcrumb from "./Breadcrumb";
import Modal from "react-modal";
import "../App.css";
import { customActionModalStyles } from "../../src/utils/helperFunctin";
import { getApi } from "../api_interface/apis";
import { ADMIN_USER, HIBEE_AUTH_URL } from "../api_interface/apiURLs";

function MainTemplate({ children, categoryName, categoryList }) {
	const user = useSelector(state => state.userReducer)

	const sidebarRef = useRef(null)
	const containerRef = useRef(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [actionModal, setActionModal] = useState({
		loader: false,
		show: false,
		type: "",
		data: {},
	});

	const [admin, setAdmin] = useState({})

	const getAdmin = async () => {
		const res = await getApi(HIBEE_AUTH_URL + ADMIN_USER + `/admin?userId=${user?._id}`);
		setAdmin(res?.data?.data?.[0])
	}

	{/* Module Access */ }
	{/* Categories */ }
	const categoriesData = [
		{ id: "6", name: "Admin Details", link: "/admin-detail" }
	]
	if (user?.access.includes('Categories')) {
		categoriesData.push({ id: "1", name: "Categories & Products", link: "/categories" })
	}

	{/* Orders */ }
	if (user?.access.includes('Orders')) {
		categoriesData.push(
			{ id: "2", name: "Orders (43)", link: "/orders" }
		)
	}

	{/* Inventories */ }
	if (user?.access.includes('Inventories')) {
		categoriesData.push(
			{
				id: "3", name: "Inventory", type: "DROPDOWN", subCategory: [
					{ id: "3.1", name: "Inventory Dashboard", link: "/inventory" },
					{ id: "3.2", name: "Inventory Product Add Form", link: "/inventory-product-add" },
				]
			}
		)
	}

	if (user?.access.includes('Users')) {
		categoriesData.push({ id: "10", name: "User Listing", link: "/user-listing" })
	}
	{/* Franchises */ }
	if (user?.access.includes('Franchises')) {
		categoriesData.push({
			id: "5", name: "Franchise Details", type: "DROPDOWN", subCategory: [
				// { id: "5.1", name: "Franchise Details", link: "/franchise-details" },
				{ id: "5.3", name: "Franchise List", link: "/franchise-listing" },
				{ id: "5.2", name: "Franchise Request Details", link: "/franchise-request-details" },

			]
		})
	}

	const selectedCategory = useSelector(state => state?.sidebarReducer)
	const [sidebar, showSidebar] = useState(true)


	useEffect(() => {
		if (sidebar) {
			sidebarRef.current.style.width = "25%"
			containerRef.current.style.width = "75%"
		} else {
			sidebarRef.current.style.width = "5%"
			containerRef.current.style.width = "95%"
		}
	}, [sidebar])

	const togglePage = (category, subCategory, type) => {
		dispatch(addCategory(category))
		if (type !== "subCategory") {
			navigate(category?.link)
		} else {
			navigate(subCategory?.link)
		}
	}

	return (
		<div className="parentContainer row h-100 m-0">

			{/* sidebar */}
			<div className="p-4 border-right" ref={sidebarRef} id="sidebar" style={{ width: "25%" }}>
				{
					sidebar
						?
						<>
							<div className="mb-4">
								<div className="h2">
									<svg onClick={() => showSidebar(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
										<path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
									</svg>
								</div>
							</div>

							<div className="mb-3">
								<div className="sidebar_logo">
									<Link to="/categories">
										<img src={Logo} className="w-100 categoryImage" />
									</Link>
								</div>
								<div className="text-uppercase">Admin</div>
							</div>

							<div className="mb-3 bg-light rounded row py-3">
								<div className="col-md-3 d-flex align-items-center"><img src={user && user?.image} className="userLogo w-50 h-50 rounded-circle" /></div>
								<div className="col-md-9 d-flex flex-row align-items-center">
									<div className="p-1 text-wrap">
										<div className="font-weight-bold">Admin</div>
										<div className="t-12">{user && user?.email}</div>
									</div>
								</div>
							</div>

							<div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
								{
									categoriesData?.map(el => (
										el?.type !== "DROPDOWN"
											?
											<div onClick={() => togglePage(el)} key={el?.id}>
												<div className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id && "selectedCategory font-weight-bold shadow-sm"}`} onClick={() => togglePage(el)}>
													{el?.name}
												</div>
											</div>
											:
											<div className="position-relative" data-bs-toggle="collapse" data-bs-target={`#accordian-${el?.id}`} key={el?.id} onClick={() => togglePage(el)}>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={`${selectedCategory?.id !== el?.id && 'grey'}`} style={{ top: "13px", right: "12px" }} className="position-absolute" viewBox="0 0 16 16">
													<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
												</svg>
												<div className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id && "selectedCategory font-weight-bold shadow-sm"}`} onClick={() => togglePage(el)}>
													{el?.name}
												</div>
												<div id={`accordian-${el?.id}`} className={`${selectedCategory?.id === el?.id && "show"}  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}>
													{
														el?.subCategory?.map(ele => (
															<div className={`py-1 px-3 rounded cursorPointer`} onClick={e => { e.stopPropagation(); togglePage(el, ele, "subCategory") }}>
																{ele?.name}
															</div>
														))
													}
												</div>
											</div>
									))
								}
							</div>

							<div className="m-0 p-0">
								<span className="mx-3">Help</span>
								<span className="mx-3">About</span>
								<span className="mx-3">Setting</span>
								<span className="mx-3 logout"
									// onClick={() => dispatch({type: "USER_LOGOUT"})}
									onClick={() => setActionModal({ show: true })}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
										<path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
										<path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
									</svg>
									<span className="mx-2">Log out</span>
								</span>
							</div>
						</>
						:
						<>
							<div className="mb-4">
								<div className="h2">
									<svg onClick={() => showSidebar(true)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
										<path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
									</svg>
								</div>
							</div>
						</>
				}
			</div>

			{/* main container */}
			<div className="p-0 bg-light" id="mainContainer" ref={containerRef} style={{ width: "75%" }}>
				<div className="mx-3 my-4 px-3">
					<div className="mt-4">

						<Breadcrumb name={categoryName} list={categoryList} />

						{children}

					</div>
				</div>

			</div>
			{/* Logout Modal */}
			<Modal
				isOpen={actionModal?.show}
				onRequestClose={() => setActionModal({})}
				style={customActionModalStyles}
				contentLabel="Action Modal"
				ariaHideApp={false}
			>
				<div className="px-3 py-3 modal_width">
					<div className="row">
						<span className="d-flex justify-content-end cursorPointer">
							<svg
								onClick={() => setActionModal({})}
								className="d-inline"
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
							</svg>
						</span>
					</div>
					<div className="row">
						<h4 className="text-danger d-flex justify-content-center">
							Do you want to Logout Admin<span>{admin?.name}</span>?
						</h4>
					</div>
					<div className="row my-5">
						<h5 className="d-flex justify-content-center text-uppercase">
							{user && user.name}
						</h5>
					</div>
					<div className="form-group my-3 d-flex justify-content-center">
						<button
							disabled={actionModal?.loader}
							className="btn btn-dark me-3 mb-2 text-warning px-5"
							onClick={() => dispatch({ type: "USER_LOGOUT" })}
						>
							{actionModal?.loader ? (
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="sr-only"></span>
								</div>
							) : (
								"Yes"
							)}
						</button>
						<button
							disabled={actionModal?.loader}
							className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
							onClick={() => setActionModal({})}
						>
							No
						</button>
					</div>
				</div>
			</Modal>
		</div>

	);
}

export default MainTemplate;

