import React, { useEffect, useState } from "react";
// import "./Orders.css";
import { Link, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import MainTemplate from "../../../components/MainTemplate";
import { FRANCHISE_ORDER, HIBEE_ORDER_URL } from "../../../api_interface/apiURLs";
import { getTokenApi } from "../../../api_interface/apis";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};
const colstyle = {
    width: "30%"
};
const tableStyle = {
    width: "100%"
};

const Prints = () => (
    <div id='divToPrint'>
        <h3>Time & Materials Statement of Work (SOW)</h3>
        <h4>General Information</h4>
        <table id="tab_customers" class="table table-striped" style={tableStyle}>
            <colgroup>
                <col span="1" style={colstyle} />
                <col span="1" style={colstyle} />
            </colgroup>
            <thead>
                <tr class="warning">
                    <th>SOW Creation Date</th>
                    <th>SOW Start Date</th>
                    <th>Project</th>
                    <th>Last Updated</th>
                    <th>SOW End Date</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Dec 13, 2017</td>
                    <td>Jan 1, 2018</td>
                    <td>NM Connect - NMETNMCM</td>
                    <td>Dec 13, 2017</td>
                    <td>Dec 31, 2018</td>
                </tr>
            </tbody>
        </table>
        <p>
            This is a Time and Materials Statement of Work between Northwestern Mutual
            Life Insurance Company and Infosys with all general terms and conditions
            as described in the current Master Agreement and its related documents
        </p>
    </div>
);

const printDocument = () => {
    const string = renderToString(<Prints />);
    const pdf = new jsPDF("p", "mm", "a4");
    console.log(typeof pdf);
    const columns = [
        "SOW Creation Date",
        "SOW Start Date",
        "Project",
        "Last Updated",
        "SOW End Date"
    ];
    var rows = [
        [
            "Dec 13, 2017",
            "Jan 1, 2018",
            "ABC Connect - ABCXYZ",
            "Dec 13, 2017",
            "Dec 31, 2018"
        ]
    ];
    // pdf.fromHTML(string);
    // pdf.html(string);

    // pdf.text(string);
    pdf.save("pdf");
};

function OrderDetails() {
    const [orderDetails, setOrderDetails] = useState([])
    const { orderId } = useParams()
    // const data = [
    // 	{ id: Math.ceil(Math.random() * 1000), img: Green, category: "Fruits & Vegetables", subCategory: "Sub Categories" },
    // 	{ id: Math.ceil(Math.random() * 1000), img: Dairy, category: "Dairy & Eggs", subCategory: "Sub Categories" },
    // 	{ id: Math.ceil(Math.random() * 1000), img: Sweets, category: "Sweet Tooth", subCategory: "Sub Categories" },
    // 	{ id: Math.ceil(Math.random() * 1000), img: Cleaner, category: "Kitchen & Cleaning", subCategory: "Sub Categories" },
    // 	{ id: Math.ceil(Math.random() * 1000), img: Bread, category: "Bread & Baker", subCategory: "Sub Categories" }
    // ]

    const handleGetOrderDetails = async () => {
        // path params
        const responce = await getTokenApi(HIBEE_ORDER_URL + FRANCHISE_ORDER + `/${orderId}`)
        if (responce.status == 200) {
            setOrderDetails([responce.data.data]);
        } else {
            console.log('failure');
        }
    }

    // console.log(orderDetails, 'orderDetails');

    useEffect(() => {
        if (orderId) {
            handleGetOrderDetails()
        }
    }, [])

    return (
        <MainTemplate categoryName="Order #12K4" categoryList={["Admin", "Orders", "#12K4"]}>
            <div className="row">
                <div className="col-8 ps-0 h-100">
                    <div className="p-4 bg-white rounded">
                        <div className="mb-2 d-flex justify-content-between align-items-center mb-4">
                            <span className="font-weight-bold">{orderDetails && orderDetails[0]?.username}</span>
                            <span class="text-white t-12 deliveredSuccess"
                                style={{
                                    backgroundColor: `${(orderDetails[0]?.status == "processing" && "#FEBF22") ||
                                        (orderDetails[0]?.status == "cancelled" && "#E93737") ||
                                        (orderDetails[0]?.status == "returned" && "#000000") ||
                                        (orderDetails[0]?.status == "delivered" && "#29AE13")
                                        }`,
                                }}
                            >{orderDetails && orderDetails[0]?.status}</span>
                        </div>

                        <div className="row t-14 mb-5">
                            <div className="col-4">
                                <div className="font-weight-bold">Order Created At</div>
                            </div>
                            <div className="">{orderDetails && (orderDetails[0]?.createdAt)?.slice(0, -5)}</div>
                            <div className="col-2">
                                <div className="font-weight-bold">Name</div>
                                <div className="">{orderDetails && orderDetails[0]?.username}</div>
                            </div>
                            <div className="col-2">
                                <div className="font-weight-bold">Flat No.</div>
                                <div className="">5243</div>
                            </div>
                            <div className="col-4">
                                <div className="font-weight-bold">Contact No.</div>
                                <div className="">{orderDetails && orderDetails[0]?.user_phone}</div>
                            </div>
                        </div>

                        <div className="row t-14 mb-5">
                            <div className="font-weight-bold">Complete Address</div>
                            <div>Flat no: 5143</div>
                            <div>Prestige Ferns</div>
                            <div>Sarjapur Road, Harlur Bengaluru</div>
                            <div>767-251-8637</div>
                        </div>
                        <div className="row t-14 mb-3">
                            {/* <span className="d-flex align-items-center cursorPointer">
								<svg className="bi bi-pencil border-bottom border-dark" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
									<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
								</svg>
								<span className="mx-1">
									Edit
									</span>
							</span> */}
                        </div>
                    </div>
                </div>
                <div className="col-4 ps-0 h-100">
                    <div className="p-4 mb-3 bg-white rounded">
                        <div className="font-weight-bold mb-2">Price</div>
                        <div className="d-flex justify-content-between t-14">
                            <span>Sub Total:</span>
                            <span>₹{" "} {orderDetails[0]?.amount}</span>
                        </div>
                        <div className="d-flex justify-content-between t-14">
                            <span>Shipping:</span>
                            <span>Free</span>
                        </div>
                        <div className="d-flex justify-content-between t-14 mb-3">
                            <span>Tax(0%):</span>
                            <span>₹ 0</span>
                        </div>
                        <div className="d-flex justify-content-between t-14 font-weight-bold">
                            <span>Total:</span>
                            <span>₹ {" "}{orderDetails[0]?.amount}</span>
                        </div>
                    </div>
                    <div className="p-4 mb-3 bg-white rounded">
                        <div className="font-weight-bold mb-2">Invoice</div>
                        <div className="d-flex justify-content-between t-14">
                            <span>Invoice No :</span>
                            <span>#5355619</span>
                        </div>
                        <div className="d-flex justify-content-between t-14">
                            <span>Seller GST :</span>
                            <span>12HY87072641Z0</span>
                        </div>
                        <div className="d-flex justify-content-between t-14 mb-3">
                            <span>Purchase GST :</span>
                            <span>22HG9838964Z1</span>
                        </div>
                        <div className="d-flex justify-content-between t-14">
                            <span><u onClick={printDocument} >Download PDF</u></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="font-weight-bold row mb-3">Order Items</div>
            <div className="row rounded px-3 bg-white">
                {/* header */}
                <div className="row m-0 ">
                    <div className="font-weight-bold t-12 text-uppercase my-3 col-md-1">
                        Photo
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase my-3 col-md-5">
                        Name
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                        Qty
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2" >
                        Price
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                        Total
                    </div>
                </div>

                {/* body */}
                <div className="m-0 p-0 pe-3">
                    {
                        orderDetails[0]?.products?.map((el) => {
                            return <div className="row border rounded m-0 px-2  mb-2 d-flex align-items-center">
                                <div className="t-12 text-uppercase my-3 col-md-1">
                                    <div className="categoryImageDiv shadow-sm  rounded p-1">
                                        <img src={el?.image} className="categoryImage h-100" />
                                    </div>
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-5">
                                    {el?.product_name}
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-2">
                                    {el.qty} (KG)
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-2" >
                                    RS{" "}{el.price}
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-2">
                                    RS {Math.ceil(Math.random() * 10000)}
                                </div>
                            </div>
                        })

                    }
                </div>
            </div>
        </MainTemplate>
    );
}

export default OrderDetails;
