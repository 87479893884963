import React, { useEffect, useMemo, useState } from "react";
import "../../superAdmin/Orders/Orders.css";
import MainTemplate from "../../../components/MainTemplate";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Form from "react-bootstrap/Form";
import { FRANCHISE_DELIVERY_STATUS, FRANCHISE_ORDER, HIBEE_ORDER_URL } from "../../../api_interface/apiURLs";
import { getTokenApi, patchTokenApi } from "../../../api_interface/apis";
import { useSelector } from "react-redux";
import moment from "moment"
import ReactModal from "react-modal";
import { customModalStyles, debounce } from "../../../utils/helperFunctin";
import { toast } from "react-toastify";

function Orders() {

  const [orders, setOrders] = useState([])
  const [loader, setLoader] = useState(false)
  const [modal, setModal] = useState({ data: [], loader: false, show: false })
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")
  const user = useSelector((state) => state?.userReducer);

  const getData = async (search) => {
    try {
      setLoader(true)
      const res = await getTokenApi(HIBEE_ORDER_URL + FRANCHISE_ORDER + `?sort=${sort}&query=${search || query || ""}`)
      if (res?.data?.data) {
        setOrders(res?.data?.data);
      }
      setLoader(false)
    } catch (err) {
      setLoader(false)
      console.log(err)
    }
  }

  const getSearchedData = useMemo(() => debounce(async (search) => {
    getData(search)
  }, 500), [])

  useEffect(() => {
    getData()
  }, [sort])

  useEffect(() => {
    getSearchedData(query)
  }, [query])

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(orders.length / itemsPerPage);
  const currentItems = orders.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orders.length;
    setItemOffset(newOffset);
  };

  const upateOrderStatus = async e => {
    try {
      setModal({ ...modal, loader: true })
      const orderId = modal?.data?.map(el => el?._id)
      const res = await patchTokenApi(HIBEE_ORDER_URL + FRANCHISE_ORDER + FRANCHISE_DELIVERY_STATUS, {
        orderId, status: e.target?.value
      })
      setModal({})
      await getData()
    } catch (err) {
      setModal({ ...modal, loader: false })
    }
  }

  const selectOrders = (e, order) => {
    // const foundIndex = modal?.data?.findIndex(el => el?._id === order?._id)
    if (e.target.checked) {
      const data = modal?.data || []
      data?.push(order)
      setModal({ ...modal, data })
    } else {
      const data = modal?.data?.filter(el => el?._id !== order?._id)
      setModal({ ...modal, data })
    }
  }

  const actionClick = () => {
    if (modal?.data?.length) {
      setModal({ ...modal, show: true })
    } else {
      toast.error("Select orders first.", { position: "top-center", autoClose: 2000 });
    }
  }

  const updateSort = e => {
    setSort(e.target.value)
  }

  return (
    <MainTemplate categoryName="Orders" categoryList={["Admin", "Orders"]}>
      {/* <div className="row mb-4 rounded p-2 bg-white">
				Orders
			</div> */}
      <div className="row mb-4 rounded p-2 bg-white">
        <div className="col-sm-6 my-3 col-md-2">All Orders</div>
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
            <option value="" selected>Sort by</option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>+
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-3">
          <input
            type="text"
            name="query"
            className="t-12 form-control"
            placeholder="Search"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        </div>
        <div className="d-none d-md-block col-md-3"></div>
        <div className="col-sm-6 my-3 col-md-2">
          <div class="dropdown">
            <button className="t-12 px-4 btn btn-dark text-warning dropdown-btn " data-menu-target={`drop-down-menu-multiple-order`} >
              Action
            </button>
            {/* <button class="dropbtn">Dropdown</button> */}
            <div id={`drop-down-menu-multiple-order`} className="dropdown-content p-2">
              <div className="dropdown-menu-option rounded px-3 py-2 d-flex justify-content-start cursorPointer" onClick={actionClick}>
                <div className="font-weight-bold">Update status</div>
              </div>
              <div className="dropdown-menu-option rounded px-3 py-2 d-flex justify-content-start cursorPointer" onClick={() => { toast.info("To be discussed", { position: "top-center", autoClose: 2000, }) }}>
                <div className="font-weight-bold" >Delete</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row rounded px-3 bg-white  text-start">
        {/* header */}
        <div className="row m-0">
          <div className="font-weight-bold t-12 text-uppercase my-3 col-md-1">
            <Form.Check type="checkbox" label="" onChange={e => setModal(e.target.checked ? { ...modal, data: currentItems } : {})} />
          </div>
          <div className="font-weight-bold t-12 text-uppercase my-3 col-md-1">
            Order Id
          </div>
          <div className="font-weight-bold t-12 text-uppercase my-3 col-md-1">
            Name
          </div>
          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
            Date
          </div>
          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
            Amount
          </div>
          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
            Status
          </div>
          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
            Order Type
          </div>
          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
            Superfast
          </div>
          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1"></div>

        </div>

        {/* body */}
        <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
          {
            loader
              ?
              <div className="text-center mt-5" >
                <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
                  Loading...
                </button>
              </div>
              :
              currentItems?.map((el) => (
                <div className="row border rounded m-0 px-2  mb-2 d-flex align-items-center text-start">
                  <div className="t-12 my-3 col-md-1">
                    <Form.Check type="checkbox" label="" checked={modal?.data?.some(o => o?._id === el?._id)} onChange={e => selectOrders(e, el)} />
                  </div>
                  <div className="t-12  my-3 col-md-1 overflow-hidden"><Link to={`/order-details/${el?._id}`}>{el?._id}</Link></div>
                  <div className="t-12  my-3 col-md-1">{el?.type === "inventory" ? user?.name : ""}</div>
                  <div className="t-12  my-3 col-md-1">{moment(el?.createdAt).format('D MMM, YYYY')}</div>
                  <div className="t-12 my-3 col-md-1">
                    {"₹"} {el?.amount}
                  </div>
                  <div className="t-12 my-3 col-md-1">
                    <div
                      className=" text-white p-1 rounded"
                      style={{
                        backgroundColor: `${(el.status == "processing" && "#FEBF22") ||
                          (el.status == "cancelled" && "#E93737") ||
                          (el.status == "returned" && "#000000") ||
                          (el.status == "delivered" && "#29AE13")
                          }`,
                        width: "70px",
                      }}
                    >
                      {el?.status}
                    </div>
                  </div>
                  <div className="t-12 my-3 col-md-2">{el?.type == 'inventory' ? "inventory Order" : "Customer Order"}</div>
                  <div className="t-12 my-3 col-md-1">{Boolean(el?.superfast) ? "Yes" : "No"}</div>
                  <div className="t-12my-3 col-md-2 text-center">
                    <div class="dropdown">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" data-menu-target={`drop-down-menu-${el?._id}`} className="bi bi-three-dots dropdown-btn cursorPointer" viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg>
                      {/* <button class="dropbtn">Dropdown</button> */}
                      <div id={`drop-down-menu-${el?._id}`} className="dropdown-content p-2">
                        <div className="dropdown-menu-option rounded px-3 py-2 d-flex cursorPointer" onClick={() => setModal({ show: true, data: [el], loader: false })}>
                          <div className="font-weight-bold">Update status</div>
                        </div>
                        <div className="dropdown-menu-option rounded px-3 py-2 d-flex cursorPointer" onClick={() => { toast.info("To be discussed", { position: "top-center", autoClose: 2000, }) }}>
                          <div className="font-weight-bold">Delete</div>
                        </div>
                        {/* <a href="#home">Home</a>
                        <a href="#about">About</a>
                        <a href="#contact">Contact</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
          }
        </div>
      </div>
      <div className="row m-2 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item mb-0"
          subContainerClassName="pages "
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>

      <ReactModal
        isOpen={modal?.show}
        onRequestClose={() => setModal({})}
        style={{ ...customModalStyles, content: { ...customModalStyles?.content, width: "40vw" } }}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg onClick={() => setModal({})} className="d-inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>

          </div>
          <div className="row d-flex justify-content-center">
            <h4 className="text-danger d-flex mb-3 justify-content-center">Select order status?</h4>
            <select disabled={modal?.loader} className="w-50 mb-3 form-select" aria-label="Default select example" outline="none" onChange={upateOrderStatus}>
              <option selected disabled>Select Status</option>
              <option value="processing">Processing</option>
              <option value="delivered">Delivered</option>
              <option value="cancelled">Cancelled</option>
              <option value="returned">Returned</option>
            </select>
          </div>
          {
            modal?.loader
              ?
              <div className="d-flex align-items-center justify-content-center">
                <div class="spinner-border text-warning spinner-border-sm" role="status"> <span class="sr-only"></span> </div>
              </div>
              :
              ""
          }
        </div>
      </ReactModal>
    </MainTemplate>
  );
}

export default Orders;
