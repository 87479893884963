import React from "react";
import "./AdminForm.css";
import MainTemplate from "../../../components/MainTemplate";
import { useState } from "react";
import {
  checkPasswordValidity,
  validateEmail,
} from "../../../utils/helperFunctin";
import { useEffect } from "react";
import { postTokenApi } from "../../../api_interface/apis";
import { ADMIN_PATH, HIBEE_ADMIN_URL } from "../../../api_interface/apiURLs";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function AdminForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const moduleList = useSelector((state) => state.sidebardModuleSliceReducer);
  const [admin, setAdmin] = useState(data ? data : {});
  const [error, setError] = useState({});
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [loader, setLoader] = useState(false);

  const onChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const validation = () => {
    const error = {};
    if (!admin?.name) error.name = "Name required";
    if (admin?.phone?.length !== 10) error.phone = "Invalid Phone";
    if (!admin?.phone) error.phone = "Phone required";
    if (!validateEmail(admin?.email)) error.email = "Invalid Email";
    if (!admin?.email) error.email = "Email required";
    // if (!admin?.role) error.role = "Role required";
    // if (!(admin?.password && checkPasswordValidity(admin?.password)))
    //   error.password = "Invalid";
    // if (!admin?.repeatPassword || admin?.password !== admin?.repeatPassword)
    //   error.repeatPassword = "Password didn't match";
    if (!(admin?.image || admin?.imageFile)) error.image = "Image Required";
    if (!admin?.access?.length) error.access = "Required";

    if (Object?.keys(error)?.length) {
      setError(error);
      return false;
    } else {
      setError({});
      return true;
    }
  };

  const setModuleAccess = (value) => {
    if (!admin?.access?.length) {
      setAdmin({ ...admin, access: [value] });
      return;
    }
    const index = admin?.access?.indexOf(value);
    if (index === -1) {
      setAdmin({ ...admin, access: [...admin?.access, value] });
    } else {
      setAdmin({
        ...admin,
        access: [
          ...admin?.access?.slice(0, index),
          ...admin?.access?.slice(index + 1),
        ],
      });
    }
  };

  const submit = async () => {
    try {
      setLoader(true);
      setSubmittedOnce(true);
      if (validation()) {
        const fd = new FormData();
        if (admin?._id) {
          fd.append("_id", admin?._id);
        }
        fd.append("name", admin?.name);
        fd.append("phone", admin?.phone);
        fd.append("email", admin?.email);
        // fd.append("role", admin?.role);
        // fd.append("password", admin?.password);
        fd.append("access", JSON.stringify(admin?.access));
        if (admin?.imageFile) {
          fd.append("image", admin?.imageFile);
        }
        const res = await postTokenApi(HIBEE_ADMIN_URL + ADMIN_PATH, fd, { "Content-Type": "multipart/form-data" });
        toast.success(res?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        navigate("/admin-detail");
      }
      setLoader(false);
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    if (Object.keys(admin)?.length && submittedOnce) {
      validation();
    }
  }, [admin]);

  return (
    <MainTemplate
      categoryName="Admin Form"
      categoryList={["Admin", "Admin Form"]}
    >
      <div className="row mb-4 rounded bg-white homepageModifierPadding mb-4">
        <div className="col-lg-6 mb-4">
          <div className="form-group py-2 pb-3">
            <label>User Name <span className="text-danger" >*</span></label>
            <input
              type="text"
              className={`w-75 placeholder-light form-control py-2 px-3 ${error?.name && "border-danger"
                }`}
              placeholder="eg: Jeff Johnson"
              autoComplete="off"
              name="name"
              value={admin?.name || ""}
              onChange={onChange}
            />
            <span className="text-danger">{error?.name}</span>
          </div>
          <div className="form-group py-2 pb-3">
            <label>Phone number <span className="text-danger" >*</span></label>
            <input
              type="number"
              name="phone"
              className={`w-75 placeholder-light form-control py-2 px-3 ${error?.phone && "border-danger"
                }`}
              placeholder="eg: (555) 555-1234"
              onChange={onChange}
              disabled
              maxLength={10}
              autoComplete='off'
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength) {
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                }
              }}
              value={admin?.phone || ""}
            />
            <span className="text-danger">{error?.phone}</span>
          </div>
          <div className="form-group py-2 pb-3">
            <label>Email ID <span className="text-danger" >*</span></label>
            <input
              type="text"
              name="email"
              className={`w-75 placeholder-light form-control py-2 px-3 && ${error?.email && "border-danger"
                }`}
              placeholder="eg: jeffjohnson@gmail.com"
              onChange={onChange}
              value={admin?.email || ""}
              disabled
            />
            <span className="text-danger">{error?.email}</span>
          </div>
          {/* <div className="form-group py-2 pb-3">
            <label>Roles</label>
            <select
              className={`w-75 py-2 px-3 form-select ${
                error?.role && "border-danger"
              }`}
              aria-label="Default select example"
              name="role"
              outline="none"
              onChange={onChange}
            >
              <option
                selected={
                  !admin?.role || typeof admin?.role === undefined
                    ? true
                    : false
                }
                disabled
              >
                Select Roles
              </option>
              <option selected={admin?.role === "hr"} value="hr">
                HR
              </option>
              <option selected={admin?.role === "2"} value="2">
                Two
              </option>
              <option selected={admin?.role === "3"} value="3">
                Three
              </option>
            </select>
            <span className="text-danger">{error?.role}</span>
          </div> */}
          {/* <div className="form-group py-2 pb-3">
            <label>New Password</label>
            <input
              type="password"
              className={`w-75 placeholder-light form-control py-2 px-3 ${
                error?.password && "border-danger"
              }`}
              name="password"
              placeholder="***********"
              onChange={onChange}
              value={admin?.password || ""}
            />
            <div
              className={`w-75 ${
                error?.password ? "text-danger" : "text-muted"
              }`}
            >
              At least 8 character, 1 special character, 1 upper and 1 lower
              case and a number
            </div>
          </div> */}
          {/* <div className="form-group py-2 pb-3 mb-5">
            <label>Repeat New Password</label>
            <input
              type="password"
              name="repeatPassword"
              className={`w-75 placeholder-light form-control py-2 px-3 ${
                error?.repeatPassword && "border-danger"
              }`}
              placeholder="***********"
              onChange={onChange}
              value={admin?.repeatPassword || ""}
            />
            <span className="text-danger">{error?.repeatPassword}</span>
          </div> */}
          <div className="form-group py-2 pb-3">
            <button
              className="btn btn-dark text-warning px-5"
              disabled={loader}
              onClick={submit}
            >
              {loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
        <div className="col-lg-4 mb-4">
          <div className="form-group py-2  mb-4 position-relative" style={{height:"200px",width:"200px"}}>
            <input
              type="file"
              name="image"
              className="homepageModifierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3"
              placeholder="eg: https://www.apple.com/in/watch"
              onChange={(e) =>
                setAdmin({
                  ...admin,
                  image: "",
                  imageFile: e?.target?.files?.[0],
                })
              }
            />
            <div className={`homepageModifierInput select-text-light d-flex align-items-center justify-content-center border rounded w-75 h-100 ${error?.image && "border-danger"}`}>
              {admin?.image ||
                (admin?.imageFile
                  ? URL.createObjectURL(admin?.imageFile)
                  : "") ? (
                <img
                  src={
                    admin?.image ||
                    (admin?.imageFile
                      ? URL.createObjectURL(admin?.imageFile)
                      : "")
                  }
                  className="rounded h-100 w-100 shadow"
                  style={{ objectFit: "fill" }}
                />
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-cloud-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                    />
                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                  </svg>
                  <span className="px-2">Upload Photo</span>
                </>
              )}
            </div>
            <span className="text-danger">{error?.image}</span>
          </div>
          <div className="py-2">
            <label className="font-weight-bold">Module Access <span className="text-danger" >*</span></label>
            <label className="mx-2 text-danger">{error?.access}</label>
            <div className="bg-light rounded py-1 mt-2 overflow-auto PageScroll">
              {
                moduleList && moduleList?.map((ele, ind) => {
                  return < div class="form-check m-4">
                    <input
                      class="form-check-input me-4 dark-checkbox"
                      type="checkbox"
                      value={ele.value}
                      name="access"
                      // onChange={() => setModuleAccess(ele?.value)}
                      id="flexCheckDefault"
                      checked={admin?.access?.includes(ele?.value)}
                      // disabled={true}
                    />
                    <label class="form-check-label" for="flexCheckDefault">{ele?.name} </label>
                  </div>
                })
              }
              {/* <div class="form-check m-4">
              <input
                class="form-check-input me-4 dark-checkbox"
                type="checkbox"
                value="Categories"
                name="access"
                onChange={() => setModuleAccess("Categories")}
                id="flexCheckDefault"
                checked={admin?.access?.includes("Categories")}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Categories
              </label>
            </div>
            <div class="form-check m-4">
              <input
                class="form-check-input me-4 dark-checkbox"
                type="checkbox"
                value="Orders"
                name="access"
                onChange={() => setModuleAccess("Orders")}
                id="flexCheckChecked"
                checked={admin?.access?.includes("Orders")}
              />
              <label class="form-check-label" for="flexCheckChecked">
                Orders
              </label>
            </div>
            <div class="form-check m-4">
              <input
                class="form-check-input me-4 dark-checkbox"
                type="checkbox"
                value="Inventories"
                name="access"
                onChange={() => setModuleAccess("Inventories")}
                id="flexCheckDefault"
                checked={admin?.access?.includes("Inventories")}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Inventories
              </label>
            </div>
            <div class="form-check m-4">
              <input
                class="form-check-input me-4 dark-checkbox"
                type="checkbox"
                value="Users"
                name="access"
                onChange={() => setModuleAccess("Users")}
                id="flexCheckChecked"
                checked={admin?.access?.includes("Users")}
              />
              <label class="form-check-label" for="flexCheckChecked">
                Users
              </label>
            </div>
            <div class="form-check m-4">
              <input
                class="form-check-input me-4 dark-checkbox"
                type="checkbox"
                value="Franchises"
                name="access"
                onChange={() => setModuleAccess("Franchises")}
                checked={admin?.access?.includes("Franchises")}
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Franchises
              </label>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </MainTemplate>
  );
}

export default AdminForm;