import React from 'react';
import { useState } from 'react'
import Modal from "react-modal"


const Breadcrumb = ({ name, list }) => {
    const [notification, showNotification] = useState(false)
    const [notificationTab, setNotificationTab] = useState("Orders")

    const customStyles = {
        content: {
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            position: "absolute",
            height: "100vh",
            background: 'rgba(0,0,0,0.1)',
            outline: 'none',
            border: "none"
        },
        overlay: {
            position: "absolute",
            heigth: "100vh",
            background: 'rgba(0,0,0,0.6)',
            zIndex: '99999'
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-4 p-0 mb-4">
                    <div>
                        <h5 className="mb-0 font-weight-bold">{name}</h5>
                        {
                            list?.map((el, index) => (
                                <span className={`t-12 ${index + 1 === list?.length && "font-weight-bold"}`}>
                                    {el}
                                    {index + 1 !== list?.length && " > "}
                                </span>
                            ))
                        }
                    </div>
                </div>
                <div className="col-8 d-flex justify-content-end position-relative">
                    <span className="badge badge-pill badge-warning position-absolute">5</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  onClick={() => showNotification(true)}  stroke="black" stroke-width=".1" fill="currentColor" className="font-weight-bold bi bi-bell" viewBox="0 0 16 16">
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                    </svg>
                </div>
            </div>

            {/* notification side modal */}
            <Modal
                isOpen={notification}
                onRequestClose={() => showNotification(false)}
                style={customStyles}
            >
                <div className={`px-3 border-right position-absolute d-block bg-white rounded shadow-lg`} style={{ width: "350px", top: 0, right: 0, zIndex: "999", height: "100vh" }}>

                    <div className="my-4">
                        <div className="h4 d-flex justify-content-between align-items-center">
                            <span>Notifications</span>
                            <svg onClick={() => showNotification(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                    </div>

                    <div className="my-4">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-6 pe-2">
                                <button className={`t-12 w-100 btn ${notificationTab === "Orders" ? "btn-dark text-warning" : "btn-outline-dark"}`} onClick={() => setNotificationTab("Orders")}>Orders Tab</button>
                            </div>
                            <div className="col-6 pe-2">
                                <button className={`t-12 w-100 btn ${notificationTab === "Inventory" ? "btn-dark text-warning" : "btn-outline-dark"}`} onClick={() => setNotificationTab("Inventory")}>Inventory</button>
                            </div>
                        </div>
                    </div>

                    <hr className="my-4" />

                    {
                        notificationTab === "Inventory"
                        &&
                        <>
                            <div className="my-5">
                                <div className="row px-2 d-flex justify-content-between align-items-center">
                                    <select className="t-12 form-select" aria-label="Default select example" outline="none">
                                        <option selected>All Notifications</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>

                            <div className="notificationScroll ps-2 pe-3 mb-3">
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Apple Running low on quantity
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-3">
                                            Only 53 apples left
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Orange expiring in 3 days
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-3">
                                            Expiring on 12/12/2023
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Apple Running low on quantity
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-3">
                                            Only 53 apples left
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Orange expiring in 3 days
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-3">
                                            Expiring on 12/12/2023
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Apple Running low on quantity
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-3">
                                            Only 53 apples left
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Orange expiring in 3 days
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-3">
                                            Expiring on 12/12/2023
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Apple Running low on quantity
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-3">
                                            Only 53 apples left
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Orange expiring in 3 days
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-3">
                                            Expiring on 12/12/2023
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                            </div>

                            <div>
                                <u className="d-flex justify-content-end font-weight-bold t-12"><span className="cursorPointer d-inline-block">View All</span></u>
                            </div>
                        </>
                    }
                    {
                        notificationTab === "Orders"
                        &&
                        <>
                            <div className="my-5">
                                <div className="row px-2 d-flex justify-content-between align-items-center">
                                    <select className="t-12 form-select" aria-label="Default select example" outline="none">
                                        <option selected>New Orders</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>

                            <div className="notificationScroll ps-2 pe-3 mb-3">
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Order no. #{Math.ceil(Math.random() * 10000)}
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-0">
                                            {Math.ceil(Math.random() * 100)} Items
                                        </div>
                                        <div className="col-7 p-0 mb-3">
                                            Punjab, Prestige Heritage
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Order no. #{Math.ceil(Math.random() * 10000)}
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-0">
                                            {Math.ceil(Math.random() * 100)} Items
                                        </div>
                                        <div className="col-7 p-0 mb-3">
                                            Ranchi, Vasant Vihar
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Order no. #{Math.ceil(Math.random() * 10000)}
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-0">
                                            {Math.ceil(Math.random() * 100)} Items
                                        </div>
                                        <div className="col-7 p-0 mb-3">
                                            Punjab, Prestige Heritage
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Order no. #{Math.ceil(Math.random() * 10000)}
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-0">
                                            {Math.ceil(Math.random() * 100)} Items
                                        </div>
                                        <div className="col-7 p-0 mb-3">
                                            Ranchi, Vasant Vihar
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Order no. #{Math.ceil(Math.random() * 10000)}
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-0">
                                            {Math.ceil(Math.random() * 100)} Items
                                        </div>
                                        <div className="col-7 p-0 mb-3">
                                            Punjab, Prestige Heritage
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Order no. #{Math.ceil(Math.random() * 10000)}
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-0">
                                            {Math.ceil(Math.random() * 100)} Items
                                        </div>
                                        <div className="col-7 p-0 mb-3">
                                            Ranchi, Vasant Vihar
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Order no. #{Math.ceil(Math.random() * 10000)}
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-0">
                                            {Math.ceil(Math.random() * 100)} Items
                                        </div>
                                        <div className="col-7 p-0 mb-3">
                                            Punjab, Prestige Heritage
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />
                                <>
                                    <div className="t-12 row px-2 font-weight-bold mb-0">
                                        Order no. #{Math.ceil(Math.random() * 10000)}
                                    </div>
                                    <div className="row px-2 t-12">
                                        <div className="col-7 p-0 mb-0">
                                            {Math.ceil(Math.random() * 100)} Items
                                        </div>
                                        <div className="col-7 p-0 mb-3">
                                            Ranchi, Vasant Vihar
                                        </div>
                                        <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                            <u>Take action</u>
                                        </div>
                                    </div>
                                </>
                                <hr className="mb-3 mt-0 w-100" />

                            </div>

                            <div>
                                <u className="d-flex justify-content-end font-weight-bold t-12"><span className="cursorPointer d-inline-block">View All</span></u>
                            </div>
                        </>
                    }
                </div>
            </Modal>

        </>
    );
};

export default Breadcrumb;