import React, { useEffect, useState, useMemo } from "react";
import ReactPaginate from "react-paginate";
import { getApi } from "../../../api_interface/apis";
import { HIBEE_AUTH_URL, HIBEE_PRODUCT_URL, USER_LISTING } from "../../../api_interface/apiURLs";
import MainTemplate from "../../../components/MainTemplate";
import "./UserListing.css";
import { debounce } from "../../../utils/helperFunctin";

function UserListing() {

  const [itemOffset, setItemOffset] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [frnachiseList, setFranchiseList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(usersData.length / itemsPerPage);
  const currenUsers = usersData.slice(itemOffset, endOffset);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % frnachiseList.length;
    setItemOffset(newOffset);
  };


  const getUsersList = async search => {
    setLoader(true)
    /** path variable**/
    const type = 'customer'
    const res = await getApi(HIBEE_AUTH_URL + USER_LISTING + `/${type}` + `?sort=${sort}&query=${search || query || ""}`);

    if (res.status == 200) {
      setUsersData(res?.data?.data);
      setLoader(false)
    }
  };



  const updateSort = e => {
    setSort(e.target.value)
  }

  const getSearchedData = useMemo(() => debounce(async (search) => {
    getUsersList(search)
  }, 500), [])

  useEffect(() => {
    getUsersList()
  }, [sort])

  useEffect(() => {
    getSearchedData(query)
  }, [query])

  console.log(currenUsers, 'currenUsers');


  return (
    <MainTemplate
      categoryName="User Listing"
      categoryList={["Admin", "User Listing"]}
    >
      <>
        <div className="row mb-4 rounded p-2 bg-white">
          <div className="col-sm-6 my-3 col-md-2">
            <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
              <option value="" selected>Sort by</option>
              <option value="name">Alphabatically</option>
              <option value="dateold">Date: Acsending</option>
              <option value="datenew">Date: Descending</option>
            </select>
          </div>
          <div className="col-sm-6 my-3 col-md-2">
            <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
              <option value="10" selected>10 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </select>
          </div>
          <div className="my-3 col-md-3">
            <input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
          </div>
          <div className="col-sm-6 my-3 col-md-2">
          </div>
          <div className="col-sm-6 my-3 col-md-2 text-end">
            <select
              className="t-12  form-select"
              aria-label="Default select example"
              style={{ backgroundColor: "#12352F", color: "#FEBF22" }}
            >
              <option selected>Actions</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          <div className="row rounded px-3 bg-white">
            <div className="row mt-2 mb-2">
              {/* header */}
              <div className="font-weight-bold t-12 text-uppercase col-md-2">
                NAME
              </div>
              <div className="font-weight-bold t-12 text-uppercase  col-md-2">
                EMAIL
              </div>
            </div>
            <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
              {currenUsers?.map((el) => (
                <div
                  className="row border rounded m-0 px-2 p-2  mb-2 d-flex align-items-center"
                  key={el?._id}
                >
                  {/* <div className="t-12 text-uppercase my-3 col-md-1"> */}
                  {/* <div className="categoryImageDiv shadow-sm  rounded p-1"> */}
                  {/* <Link
                        className="text-dark"
                        to={`/usersData/${el?._id}`}
                        style={{ textDecoration: "none" }}
                      > */}
                  {/* <img
                        src={el?.image}
                        className="categoryImage h-100 rounded"
                      /> */}
                  {/* </Link> */}
                  {/* </div> */}
                  {/* </div> */}
                  <div className="t-12 text-uppercase my-3 col-md-2">
                    {el?.name}
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-9">
                    {el?.email}
                  </div>
                  {/* <div className="t-12 text-uppercase my-3 col-md-4">
                    <svg
                      className="bi bi-pencil border-bottom border-dark"
                      onClick={() => {
                        setModal(true);
                        setcategory(el);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                  </div> */}
                  <div className="t-12 text-uppercase my-3 col-md-1">
                    <div class="dropdown">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        data-menu-target={`drop-down-menu-${el?._id}`}
                        className="bi bi-three-dots dropdown-btn cursorPointer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg>
                      <div
                        id={`drop-down-menu-${el?._id}`}
                        className="dropdown-content p-2"
                      >
                        <div
                          className="px-3 py-2 d-flex cursorPointer"
                        // onClick={() =>
                        //   setActionModal({
                        //     ...actionModal,
                        //     show: true,
                        //     data: el,
                        //     type: "DELETE",
                        //   })
                        // }
                        >
                          <span className="mx-2 d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                          </span>
                          <span className="mx-2">
                            <div className="font-weight-bold">Delete</div>
                            <div className="">Delete this product</div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ))}
              {loader == true &&
                <div className="text-center mt-5" >
                  <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
                    Loading...
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="row m-2 d-flex justify-content-center align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item mb-0"
            subContainerClassName="pages "
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </>
    </MainTemplate>
  );
}

export default UserListing;
