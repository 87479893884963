import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {
  Row,
  Col,
  Form,
  FormControl,
  Container,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { putApi } from "../../api_interface/apis";
import { checkPasswordValidity } from "../../utils/helperFunctin";
import { toast } from "react-toastify";
import { HIBEE_AUTH_URL, REACT_CHANGE_PASSWORD_API_PATH } from "../../api_interface/apiURLs";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFEDC2",
    border: "1px solid #ced4da",
    // width: "460px",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function NewPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const phone = location?.state?.phone;

  const [passwordObj, setPasswordObj] = useState({
    password: "",
    rePassword: "",
  });
  const [error, setError] = useState({ password: "", rePassword: "" });
  const [loader, setLoader] = useState(false);
  const [PasswordVisible, setPasswordVisible] = useState("no");

  const validation = () => {
    const { password, rePassword } = passwordObj;
    let errorObj = {};
    if (!password) {
      errorObj.password = "Enter Password";
    }
    if (!rePassword) {
      errorObj.rePassword = "Re-enter Password";
    }
    if (!(password && checkPasswordValidity(password))) {
      errorObj.password =
        "Password must contain min 8 letter, 1 special character, 1 upper and 1 lower case and a number";
    }
    if (password !== rePassword) {
      errorObj.rePassword = "Password didn't matched";
    }
    if (Object.keys(errorObj)?.length) {
      setError(errorObj);
      return false;
    } else {
      setError({});
      return true;
    }
  };
  const submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (validation()) {
      try {
        if (phone) {
          await putApi(HIBEE_AUTH_URL + REACT_CHANGE_PASSWORD_API_PATH, {
            password: passwordObj?.password,
            phone,
          });
          toast.success("Password changed successfully", {
            position: "top-center",
            autoClose: 2000,
          });
          navigate("/");
        }
      } catch (err) {
        const message = err?.response?.data?.message || "Something went wrong";
        toast.error(message, { position: "top-center", autoClose: 2000 });
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    setError({
      ...error,
      password: "",
      rePassword: "",
    });
  }, [passwordObj]);

  const handlePasswordVisible = () => {
    setPasswordVisible("no");
  };
  const handlePasswordNotVisible = () => {
    setPasswordVisible("yes");
  };

  return (
    <div className="main-div">
      <Container fluid>
        <Row className="pt-5">
          <Col xs lg="3.5" className=""></Col>
          <Col xs lg="5" className="g-0 ">
            <div className="first-box">
              <div className="d-flex justify-content-center">
                <div className="hibee-logo mt-5"></div>
              </div>
              <div className=" d-flex justify-content-center">
                <div className="second-boxd mt-5">
                  <form onSubmit={submit}>
                    <div>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          className="form-control p-3"
                          placeholder="enter password here"
                          name="password"
                          onChange={(e) =>
                            setPasswordObj({
                              ...passwordObj,
                              password: e.target.value,
                            })
                          }
                          value={passwordObj?.password}
                          isInvalid={error?.password}
                        />
                        <FormControl.Feedback
                          type="invalid"
                          className="text-start"
                        >
                          {error?.password}
                        </FormControl.Feedback>
                      </Form.Group>
                    </div>
                    <div className="d-flex">
                      <div className="col-md-12">
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Repeat New Password</Form.Label>
                          <Form.Control
                            type={
                              PasswordVisible == "yes" ? "text" : "password"
                            }
                            className="form-control p-3"
                            placeholder="enter re-new password here"
                            name="reenterPassword"
                            onChange={(e) =>
                              setPasswordObj({
                                ...passwordObj,
                                rePassword: e.target.value,
                              })
                            }
                            value={passwordObj.rePassword}
                            isInvalid={error?.rePassword}
                          />
                          <FormControl.Feedback
                            type="invalid"
                            className="text-start"
                          >
                            {error?.rePassword}
                          </FormControl.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col mt-5">
                        <div className="">
                          {PasswordVisible == "no" && (
                            <div
                              onClick={handlePasswordNotVisible}
                              style={{
                                position: "relative",
                                right: "30px",
                                top: "0px",
                              }}
                            >
                              <AiOutlineEyeInvisible
                                size={20}
                              ></AiOutlineEyeInvisible>
                            </div>
                          )}
                          {PasswordVisible == "yes" && (
                            <div
                              onClick={handlePasswordVisible}
                              style={{
                                position: "relative",
                                right: "30px",
                                top: "0px",
                              }}
                            >
                              <AiOutlineEye size={20}></AiOutlineEye>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                      <button
                        className="login-button mt-4"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : (
                          <p className="login-text">Submit</p>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Col>
          <Col xs lg="3.5" className=""></Col>
        </Row>
      </Container>
    </div>
  );
}

export default NewPassword;
