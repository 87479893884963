import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Vector1 from "../../assets/images/Vector (1).png";
import Vector from "../../assets/images/Vector.png";
import React from "react";
import "./FeedbackInternal.css";
import MainTemplate from "../../components/MainTemplate";

function FeedbackInternal() {
  return (
      <MainTemplate
        categoryName="Feedback #78574"
      >
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-1"></div>
        <div className="col-md-3 row justify-content-center">
          <div className="md-3 mt-2">
            <div className="md-3 mb-3">
              <div className="fntdark">Name</div>
              <div className="fnyclss underli">Jharna Jhopal Jha</div>
            </div>

            <div className="md-3 mb-3">
              <div className="fntdark">Assigned Franchise No.</div>
              <div className="fnyclss underli">#783746635562</div>
            </div>

            <div className="md-3 mb-3">
              <div className="fntdark">Phone Number</div>
              <div className="fntfant underli">+91-987-675-6748</div>
            </div>

            <div className="md-3 mb-3">
              <div className="fntdark">Address</div>
              <div className="fntfant boox2">
                #152 Ram Sang Market, 123 Main Street, Om mandir, Jalandhar,
                Punjab, 560078.
              </div>
            </div>

            <div className="md-3 mb-3">
              <div className="fntdark">Order Number</div>
              <div className="fntfant underli">#7837</div>
            </div>
          </div>
        </div>

        <div className="col-1 py-0 horriLiness"></div>

        <div className="col-md-5 md-3 px-0">
          <div className=" md-3 mt-3 mb-3">
            <div className="fntdark">Feedback ID</div>
            <div className="fnyclss">#78574</div>
          </div>

          <div className=" md-3 mb-3">
            <div className="fntdark">User Rating</div>
            <div>
              <img src={Vector} className="imgvect" />
              <img src={Vector} className="imgvect" />
              <img src={Vector} className="imgvect" />
              <img src={Vector1} className="imgvect" />
              <img src={Vector1} className="imgvect" />
            </div>
          </div>

          <div className="md-3 mb-3 boxmes">
            <div className="fntdark">Message</div>
            <div className="fntfant boox">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit
              in tempor, enim. Mi, aliquam egestas neque turpis sed. Integer
              commodo penatibus tortor eu tortor ut lobortis. Lacus sagittis,
              amet, ut justo, vehicula.
              <p />
              Sit ut eu semper facilisis tellus felis sed id quis. In erat non,
              fusce in mi. Cras sem sem nulla donec pharetra ultrices ut.
              Malesuada sem commodo.
            </div>
          </div>

          <div className="md-3"></div>

          <div className="md-3 mt-5">
            <button className="btn btn-success feedbtn">
              Forward email to franchise
            </button>
          </div>
        </div>
      </div>
    </div>
    </MainTemplate>
  );
}

export default FeedbackInternal;
