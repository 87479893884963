import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import "./FranchiseDetails.css";
import Edit from "../../../assets/images/Edit.png";
import Profile from "../../../assets/images/profile.png";
import MainTemplate from "../../../components/MainTemplate";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getApi } from "../../../api_interface/apis";
import { FRANCHISE_LIST_API_PATH, HIBEE_PRODUCT_URL } from "../../../api_interface/apiURLs";

function FranchiseDetails() {
  const [franchiseDetails, setFraDetails] = useState([])
  const [loader, setLoader] = useState(false)
  const frenchiseId = useParams()
  const handleDetails = async () => {
    if (frenchiseId) {
      setLoader(true)
      await getApi(HIBEE_PRODUCT_URL + FRANCHISE_LIST_API_PATH + `/${frenchiseId?.franchiseId}`)
        .then((ele, ind) => {
          if (ele.status == 200) {
            setLoader(false)
            setFraDetails(ele?.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false)
        });
    }
  }

  useEffect(() => {
    handleDetails()
  }, [])


  return (
    <MainTemplate categoryName="Franchise #648439" categoryList={["Admin", "Franchise", '#648439']}>
      <div className="containerbg overflow-auto rounded bg-white row w-100" >
        <div className="container">

          {
            loader == false &&
            <div className="row">
              <div className="rowhi"></div>
              <div className="col-md-5 row justify-content-center">
                <div className="col-6 mt-4" >
                  <img src={franchiseDetails?.profile_img} className="imge mb-4" />
                  <div className="box1 md-3 mb-3">
                    <div className="size1">Name</div>
                    <div className="size2 color1">{franchiseDetails?.owner_name}</div>
                  </div>

                  <div className="box2 md-3 mb-3">
                    <div className="size1">Phone Number</div>
                    <div className="size2 color1 underli">{franchiseDetails?.phone_number}</div>
                  </div>

                  <div className="box3 md-3 mb-3">
                    <div className="size1">Email</div>
                    <div className="size2 color1 underli">{franchiseDetails?.email}</div>
                  </div>

                  <div className="box4 md-3 mb-3">
                    <div className="size1">Unique code</div>
                    <div className="size2 color1">{franchiseDetails?.unique_code}</div>
                  </div>
                  <div>
                    <div className="box4 md-3 mb-3">
                      <div className="size1">COD</div>
                      <label className="switch">
                        <input className="switch-input" type="checkbox"
                          name='cod_status'
                          checked={franchiseDetails?.cod_status}
                        />
                        <span className="switch-label"></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-1">
                <div className="horriLine"></div>
              </div>

              <div className="col-md-3 part2 md-3">
                <div className="box5 md-3 mt-4">
                  <div className="size1">Address</div>
                  <div className="addr">
                    {franchiseDetails?.address?.street}
                  </div>
                </div>

                <div className="box6 md-3 mb-3">
                  <div className="size1">Society</div>
                  <div className="size2 color2">{franchiseDetails?.society}</div>
                </div>

                <div className="box7 md-3 mb-3">
                  <div className="size1">Reffered by</div>
                  <div className="size2 color2">{franchiseDetails?.referrals}</div>
                </div>

                <div className="md-3 mb-3">
                  <div className="size1">Aadhar card</div>
                  <div className="size2 color2">
                    <img src={franchiseDetails?.adhar_img} width='200px' height='100px' />
                  </div>
                </div>

                <div className="md-3 mb-3">
                  <div className="size1">PAN</div>
                  <div className="size2 color2">
                    <img src={franchiseDetails?.pan_img} width='200px' height='100px' />
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-3 row align-items-end">
                <div className="last col-8">
                  {/* <div className="col-9 md-3"></div>
               <img src={Edit} />
               <div className="Edits" onClick={handEditClick} >
                 Edit
               </div> */}
                </div>
              </div>
            </div>
          }

          {
            loader == true &&
            <div className="text-center mt-5">
              <button
                class="btn mt-5"
                style={{ backgroundColor: "#FFEDC2" }}
                type="button"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Loading...
              </button>
            </div>
          }
        </div>
      </div>
    </MainTemplate>
  );
}

export default FranchiseDetails;
