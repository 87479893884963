import React, { useEffect } from "react";
import {
	Form,
	Button,
	FormGroup,
	FormControl,
	ControlLabel,
} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import "./InventoryProductAddForm.css";
import placeholder from "../../../assets/images/placeholder.png";
import MainTemplate from "../../../components/MainTemplate";
import { useState } from "react";
import { getTokenApi, postTokenApi } from "../../../api_interface/apis";
import {
	CATEGORY_PATH,
	CATEGORY_SUB_LIST_PATH,
	HIBEE_PRODUCT_URL,
	PRODUCT_PATH,
} from "../../../api_interface/apiURLs";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import Barcode from 'react-jsbarcode';

function InventoryProductAddForm() {
	const navigate = useNavigate();
	const location = useLocation();
	const data = location?.state?.data;
	const [submittedOnce, setSubmittedOnce] = useState(false);
	const [loader, setLoader] = useState(false);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [productImage, setProductImage] = useState("");
	const [inventoryData, setInventoryData] = useState(
		data
			? data
			: {
				product_code: "",
				product_name: "",
				product_variant: "",
				manufacture_name: "",
				manufacturing_date: "",
				expiration_date: "",
				qty: "",
				warehouse: "",
				category: "",
				subcategory: "",
				type: "",
				batch: "",
				image: "",
				price: "",
				cutout_price: "",
				commission: "",
				sizeList: "",
			}
	);
	const [inventoryDataError, setInventoryDataError] = useState({});

	/**Handle Validation **/
	const handleValidation = () => {
		const newErrors = {};

		if (!inventoryData?.product_code) {
			newErrors.product_code = "Please enter unique code";
		}
		if (!inventoryData?.product_name) {
			newErrors.product_name = "Please enter product name";
		}
		if (!inventoryData?.product_variant) {
			newErrors.product_variant = "Please enter product variant";
		}
		if (!inventoryData?.manufacture_name) {
			newErrors.manufacture_name = "Please enter manufacture name";
		}
		if (!inventoryData?.manufacturing_date) {
			newErrors.manufacturing_date = "Please select product MFG date";
		}
		if (!inventoryData?.expiration_date) {
			newErrors.expiration_date = "Please select product expiry date";
		}
		if (!inventoryData?.qty) {
			newErrors.qty = "Please enter qty name";
		}
		if (!inventoryData?.warehouse) {
			newErrors.warehouse = "Please select warehouse";
		}
		if (!inventoryData?.category) {
			newErrors.category = "Please select category";
		}
		if (!inventoryData?.subcategory) {
			newErrors.subcategory = "Please select sub category";
		}
		if (!inventoryData?.type) {
			newErrors.type = "Please enter storage type";
		}
		if (!inventoryData?.batch) {
			newErrors.batch = "Please enter product batch";
		}
		if (!(inventoryData?.image || productImage)) {
			newErrors.image = "Please upload product variant";
		}
		if (!inventoryData?.price) {
			newErrors.price = "Please enter price";
		}
		if (!inventoryData?.cutout_price) {
			newErrors.cutout_price = "Please enter cutout price";
		}
		if (!inventoryData?.commission) {
			newErrors.commission = "Please enter commission";
		}

		if (Object.keys(newErrors)?.length) {
			setInventoryDataError(newErrors);
			return false;
		} else {
			setInventoryDataError({});
			return true;
		}
	};

	/** haandle change **/
	const handleInventorychange = (event, type) => {
		if (type !== "image") {
			setInventoryData({
				...inventoryData,
				[event.target.name]: event.target.value,
			});
			setInventoryDataError({
				...inventoryDataError,
				[event.target.name]: null,
			});
		} else {
			var url = URL.createObjectURL(event.target.files[0]);
			setProductImage(event.target.files[0]);
			setInventoryData({
				...inventoryData,
				[event.target.name]: url,
			});
			setInventoryDataError({
				...inventoryDataError,
				[event.target.name]: null,
			});
		}
	};

	/** handle validation **/
	const handleSubmitInventory = async (e) => {
		e.preventDefault();
		try {
			setLoader(true);
			setSubmittedOnce(true);

			if (handleValidation()) {
				const fd = new FormData();

				if (inventoryData._id) {
					fd.append("_id", inventoryData._id);
				}
				fd.append("product_code", inventoryData?.product_code);
				fd.append("product_name", inventoryData?.product_name);
				fd.append("product_variant", inventoryData?.product_variant);
				fd.append("manufacture_name", inventoryData?.manufacture_name);
				fd.append("manufacturing_date", inventoryData?.manufacturing_date);
				fd.append("expiration_date", inventoryData?.expiration_date);
				fd.append("qty", inventoryData?.qty);
				fd.append("warehouse", inventoryData?.warehouse);
				fd.append("category", inventoryData?.category);
				fd.append("subcategory", inventoryData?.subcategory);
				fd.append("type", inventoryData?.type);
				fd.append("batch", inventoryData?.batch);
				if (productImage) {
					fd.append("image", productImage);
				}
				fd.append("price", inventoryData?.price);
				fd.append("cutout_price", inventoryData?.cutout_price);
				fd.append("commission", inventoryData?.commission);
				fd.append("sizeList", JSON.stringify({ size: "500 g", quantity: 17 }));

				const res = await postTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH, fd, {
					"Content-Type": "multipart/form-data",
				});
				toast.success(res?.data?.message, {
					position: "top-center",
					autoClose: 2000,
				});
				navigate("/products");
			}
			setLoader(false);
		} catch (err) {
			toast.error(err?.response?.data?.message, {
				position: "top-center",
				autoClose: 2000,
			});
			setLoader(false);
		}
	};

	const getCategories = async () => {
		const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH);
		setCategories(res?.data?.data);
	};

	const getSubCtegories = async () => {
		const res = await postTokenApi(
			HIBEE_PRODUCT_URL + CATEGORY_SUB_LIST_PATH + `/${inventoryData?.category}`
		);
		setSubCategories(res?.data?.message?.[0]?.subCategories);
	};

	useEffect(() => {
		if (inventoryData?.category) getSubCtegories();
	}, [inventoryData?.category]);

	useEffect(() => {
		if (Object.keys(inventoryData)?.length && submittedOnce) {
			handleValidation();
		}
	}, [inventoryData]);

	useEffect(() => {
		getCategories();
	}, []);

	return (
		<MainTemplate
			categoryName="Inventory"
			categoryList={["Admin", "Inventory", "Add"]}
		>
			<Form onSubmit={handleSubmitInventory}>
				<Row>
					<div className="row mb-4 rounded bg-white homepageModifierPadding mb-4">
						<div className="col-lg-9 mb-4">
							<Form.Group
								className="form-group py-2 pb-3"
								controlId="Unique Code"
							>
								<Form.Label>Unique Code</Form.Label>
								<div className="col-4">
									<Form.Control
										type="number"
										value={inventoryData?.product_code}
										placeholder="#66778"
										name="product_code"
										className="form-control"
										onChange={handleInventorychange}
										isInvalid={inventoryDataError?.product_code}
										autoComplete="off"
										maxLength={7}
										onInput={(e) => {
											if (e.target.value.length > e.target.maxLength) {
												e.target.value = e.target.value.slice(0, e.target.maxLength);
											}
										}}
									/>
								</div>
								<span className="text-danger">
									{inventoryDataError.product_code}
								</span>
							</Form.Group>
							<Form.Group className="mb-3" controlId="Product Name">
								<Form.Label>Product Name</Form.Label>
								<Form.Control
									className="Textbar"
									type="text"
									placeholder="Product name"
									value={inventoryData?.product_name}
									name="product_name"
									isInvalid={inventoryDataError?.product_name}
									autoComplete="off"
									onChange={handleInventorychange}
								/>
								<span className="text-danger">
									{inventoryDataError.product_name}
								</span>
							</Form.Group>
							<Form.Group className="mb-3" controlId="Product variant">
								<Form.Label>Product variant</Form.Label>
								<Form.Control
									className="Textbar"
									type="text"
									placeholder="Enter product variant"
									name="product_variant"
									value={inventoryData?.product_variant}
									isInvalid={inventoryDataError?.product_variant}
									autoComplete="off"
									onChange={handleInventorychange}
								/>
								<span className="text-danger">
									{inventoryDataError.product_variant}
								</span>
							</Form.Group>
							<Form.Group className="mb-3" controlId="Manufacture Name">
								<Form.Label>Manufacture Name</Form.Label>
								<Form.Control
									className="Textbar"
									type="text"
									placeholder="Enter manufacture name"
									name="manufacture_name"
									value={inventoryData?.manufacture_name}
									isInvalid={inventoryDataError?.manufacture_name}
									autoComplete="off"
									onChange={handleInventorychange}
								/>
								<span className="text-danger">
									{inventoryDataError.manufacture_name}
								</span>
							</Form.Group>

							<div className="mb-3">
								<div style={{ width: "448px" }}>
									<Row>
										<div className="col-6">
											<Form.Group className="mb-3" controlId="Product MFG Date">
												<Form.Label>Product MFG Date</Form.Label>
												<Form.Control
													className="smtext"
													type="date"
													placeholder="Select MFG date"
													name="manufacturing_date"
													value={inventoryData?.manufacturing_date}
													onChange={handleInventorychange}
													isInvalid={inventoryDataError?.manufacturing_date}
													autoComplete="off"
												/>
												<span className="text-danger">
													{inventoryDataError.manufacturing_date}
												</span>
											</Form.Group>
										</div>
										<div className="col-6">
											<Form.Group className="mb-3" controlId="Product Expiry Date">
												<Form.Label>Best Before/Expiry</Form.Label>
												<Form.Control
													className="smtext"
													type="date"
													placeholder="Select Expiry date"
													name="expiration_date"
													value={inventoryData?.expiration_date}
													onChange={handleInventorychange}
													isInvalid={inventoryDataError?.expiration_date}
													autoComplete="off"
												/>
												<span className="text-danger">
													{inventoryDataError.expiration_date}
												</span>
											</Form.Group>
										</div>
									</Row>
								</div>
							</div>

							<div className="mb-3">
								<div style={{ width: "448px" }}>
									<Row>
										<div className="col-6">
											<Form.Group className="mb-3" controlId="Product Qty">
												<Form.Label>Product Qty</Form.Label>
												<Form.Control
													className="smtext"
													type="number"
													placeholder="Enter qty name"
													name="qty"
													value={inventoryData?.qty}
													onChange={handleInventorychange}
													isInvalid={inventoryDataError?.qty}
													autoComplete="off"
												/>
												<span className="text-danger">
													{inventoryDataError.qty}
												</span>
											</Form.Group>
										</div>
										<div className="col-6">
											<Form.Group className="mb-3" controlId="Product Qty">
												<Form.Label>Select Warehouse</Form.Label>
												<Form.Select
													className="mb-3 smtext"
													controlId="Select Warehouse"
													type="text"
													placeholder="Enter Select Warehouse"
													name="warehouse"
													onChange={handleInventorychange}
													value={inventoryData?.warehouse}
													isInvalid={inventoryDataError?.warehouse}
													autoComplete="off"
												>
													<option value="Whitefield">Whitefield</option>
													<option value="London">London</option>
													<option value="Delhi">Delhi</option>
												</Form.Select>
											</Form.Group>
											<span className="text-danger">
												{inventoryDataError.warehouse}
											</span>
										</div>
									</Row>
								</div>
							</div>
							<Row>
								<div className="col mb-3">
									<Form.Label>Product category</Form.Label>
									<Form.Select
										className="Textbar"
										controlId="Product category"
										type="text"
										placeholder="Enter select product category"
										name="category"
										onChange={handleInventorychange}
										isInvalid={inventoryDataError?.category}
										autoComplete="off"
									>
										<option selected disabled>
											Select category
										</option>
										{categories?.map((el, index) => (
											<option key={index} value={el?._id}>{el?.name}</option>
										))}
									</Form.Select>
									<span className="text-danger">
										{inventoryDataError.category}
									</span>
								</div>
							</Row>

							<Row>
								<div className="col mb-3">
									<Form.Label>Product sub-category</Form.Label>
									<Form.Select
										className="Textbar"
										controlId="Product category"
										type="text"
										placeholder="Enter select product category"
										name="subcategory"
										onChange={handleInventorychange}
										isInvalid={inventoryDataError?.subcategory}
										autoComplete="off"
									>
										<option selected disabled>
											Select sub-category
										</option>
										{subCategories?.map((el, index) => (
											<option key={index} value={el?._id}>{el?.name}</option>
										))}
									</Form.Select>
									<span className="text-danger">
										{inventoryDataError.subcategory}
									</span>
								</div>
							</Row>

							<div className="mb-3">
								<div style={{ width: "448px" }}>
									<Row>
										<div className="col-6">
											<Form.Group className="mb-3" controlId="Storage Type">
												<Form.Label>Storage Type</Form.Label>
												<Form.Control
													className="smtext"
													type="text"
													placeholder="Enter storage name"
													name="type"
													value={inventoryData?.type}
													onChange={handleInventorychange}
													isInvalid={inventoryDataError?.type}
													autoComplete="off"
												/>
												<span className="text-danger">
													{inventoryDataError.type}
												</span>
											</Form.Group>
										</div>
										<div className="col-6">
											<Form.Group className="mb-3" controlId="Product Batch">
												<Form.Label className="margintext">
													Product Batch
												</Form.Label>
												<Form.Control
													className="smtext"
													type="text"
													placeholder="Enter batch name"
													name="batch"
													value={inventoryData?.batch}
													onChange={handleInventorychange}
													isInvalid={inventoryDataError?.batch}
													autoComplete="off"
												/>
												<span className="text-danger">
													{inventoryDataError.batch}
												</span>
											</Form.Group>
										</div>
									</Row>
								</div>
							</div>

							<Form.Group className="mb-3" controlId="Price">
								<Form.Label>Price</Form.Label>
								<Form.Control
									className="Textbar"
									type="number"
									placeholder="Enter Price"
									name="price"
									value={inventoryData?.price}
									isInvalid={inventoryDataError?.price}
									autoComplete="off"
									onChange={handleInventorychange}
								/>
								<span className="text-danger">{inventoryDataError.price}</span>
							</Form.Group>

							<Form.Group className="mb-3" controlId="Cutout Price">
								<Form.Label>Cutout Price</Form.Label>
								<Form.Control
									className="Textbar"
									type="number"
									placeholder="Enter Cutout Price"
									name="cutout_price"
									isInvalid={inventoryDataError?.cutout_price}
									autoComplete="off"
									value={inventoryData?.cutout_price}
									onChange={handleInventorychange}
								/>
								<span className="text-danger">
									{inventoryDataError.cutout_price}
								</span>
							</Form.Group>

							<Form.Group className="mb-3" controlId="Commission">
								<Form.Label>Commission</Form.Label>
								<Form.Control
									className="Textbar"
									type="number"
									placeholder="Enter Commission"
									name="commission"
									value={inventoryData?.commission}
									isInvalid={inventoryDataError?.commission}
									autoComplete="off"
									onChange={handleInventorychange}
								/>
								<span className="text-danger">
									{inventoryDataError.commission}
								</span>
							</Form.Group>
						</div>
						<div className="col-lg-3 mb-4">
							<div className="py-2 mb-3">
								<label>Bar Code</label>
								<Row>
									<Barcode className="d-flex" options={{ format: 'code128', width: "2", marginLeft: 0, text: inventoryData?.product_code || "Unique Code" }} value={inventoryData?.product_code || " "} />
								</Row>
							</div>
							{/* <div className="py-2">
					      <label>Bar code</label>
					      {
                  true 
                  ?
                  <Row>
                    <Form.Group className="mb-3" controlId="Pan Card">
                      <div className="form-group py-2 position-relative mb-3">
                        <input type="file"
                          name='image'
                          onChange={handleInventorychange}
                          isInvalid={inventoryDataError?.image}
                          className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3" />
                        <div className="profile-box text-center" >
                          <div  style={{marginTop:"80px"}}>
                            <img src={placeholder} height='20px' width='20px' />{" "}<span style={{ color: '#A3A3A3' }}>Upload Photo</span>
                          </div>
                        </div>
                      </div>
                      <span className="text-danger" >{inventoryDataError.image}</span>
                    </Form.Group>
                  </Row>
                  :
                  <Row>
                    <div className="profile-box mb-3 g-0 d-flex justify-content-center align-items-center" >
                      <img src={inventoryData.image} width='100%' height='100%' />
                    </div>
                  </Row>
                }
              </div> */}
							<div className="py-2 mb-3">
								<label>QR Code</label>
								<Row className="d-flex justify-content-center">
									<QRCode style={{ height: "250px", width: "250px" }} value={JSON.stringify(inventoryData)} />
								</Row>
							</div>
							<div className="py-2">
								<label>Product Image</label>
								{inventoryData?.image == "" ? (
									<Row>
										<Form.Group className="mb-3" controlId="Pan Card">
											<div className="form-group py-2 position-relative mb-3">
												<input
													type="file"
													name="image"
													onChange={(e) => handleInventorychange(e, "image")}
													isInvalid={inventoryDataError?.image}
													className="opacity0 position-absolute placeholder-light form-control py-2 w-100 h-100  px-3"
												/>
												<div
													className={`d-flex align-items-center justify-content-center flex-column border rounded text-center ${Boolean(inventoryDataError?.image) &&
														"border-danger"
														} `}
													style={{ height: "200px" }}
												>
													<div>
														<img src={placeholder} />{" "}
														<span style={{ color: "#A3A3A3" }}>
															Upload Photos
														</span>
													</div>
												</div>
											</div>
											<span className="text-danger">
												{inventoryDataError.image}
											</span>
										</Form.Group>
									</Row>
								) : (
									<Row>
										<div className=" mb-3 g-0 d-flex justify-content-center position-relative align-items-center">
											<input
												type="file"
												name="image"
												onChange={(e) => handleInventorychange(e, "image")}
												isInvalid={inventoryDataError?.image}
												className="opacity0 position-absolute placeholder-light form-control py-2 w-100 h-100 px-3"
											/>
											<img
												src={
													inventoryData?.image || (productImage ? URL.createObjectURL(productImage) : "")
												}
												className="rounded shadow"
												width="100%"
												height="100%"
											/>
										</div>
									</Row>
								)}
							</div>
						</div>
						<div className="form-group py-2 pb-3">
							<Button
								variant="primary"
								type="submit"
								size="lg"
								id="btnRegister"
							>
								Save Product
							</Button>
						</div>
					</div>
				</Row>
			</Form>
		</MainTemplate>
	);
}

export default InventoryProductAddForm;
